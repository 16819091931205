const viewports = {
    small: [320, 539],
    smallWide: [540, 767],
    medium: [768, 1023],
    large: [1024, 1199],
    xlarge: [1200, 1399],
    xxlarge: [1400, 1919],
    xxxlarge: [1920]
}

export const queries = Object.keys(viewports).reduce((queries, key) => {
    const viewport = viewports[key].map(value => value / 16)

    queries[`${key}Up`] = `screen and (min-width: ${viewport[0]}em)`

    if (viewport.length === 2) {
        queries[`${key}Only`] = `screen and (min-width: ${viewport[0]}em) and (max-width: ${viewport[1]}em)`
        queries[`${key}Max`] = `screen and (max-width: ${viewport[1]}em)`
    }

    return queries
}, {})

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAY74u_qbLMqdIQEFSXRXDvgFs_cYqwojM'

export const basePath = document.querySelector('html').dataset.basepath || '';

export const componentKey = '__giant_component__'
import $ from 'jquery';
import { loadComponents, removeComponents } from '../../core/componentUtils';
import EventBus from '../../core/EventBus'

$.nette.ext('snippets', null);
$.nette.ext('snippets', {
    success: function (payload) {
        if (payload.snippets) {
            this.updateSnippets(payload.snippets, payload.append, null, payload);
        }
    }
}, {
    beforeQueue: $.Callbacks(),
    afterQueue: $.Callbacks(),
    completeQueue: $.Callbacks(),
    before: function (callback) {
        this.beforeQueue.add(callback);
    },
    after: function (callback) {
        this.afterQueue.add(callback);
    },
    complete: function (callback) {
        this.completeQueue.add(callback);
    },
    updateSnippets: function (snippets, append, back, payload) {
        var that = this;
        var elements = [];
        for (var i in snippets) {
            var $el = this.getElement(i);
            if ($el.get(0)) {
                elements.push($el.get(0));
            }
            this.updateSnippet($el, snippets[i], append, back, payload);
        }
        $(elements).promise().done(function () {
            that.completeQueue.fire();
        });
    },
    updateSnippet: function ($el, html, append, back, payload) {
        const snippet = $el.data('_snippet');
        // Fix for setting document title in IE
        if ($el.is('title')) {
            document.title = html;
        } else if (snippet) {
            snippet.update({
                html,
                append,
                back,
                payload
            });
        } else {
            this.beforeQueue.fire($el);
            this.applySnippet($el, html, append, back, payload);
            this.afterQueue.fire($el);
        }
    },
    getElement: function (id) {
        return $('#' + this.escapeSelector(id));
    },
    applySnippet: function ($el, html, append, back, payload) {
        console.info(`Aktualizuji snippet "${$el.attr('id')}"`);
        if (!back && ($el.is('[data-ajax-append]') || ($el.attr('id') == append))) {
            $el.append(html);
        } else if (!back && $el.is('[data-ajax-prepend]')) {
            $el.prepend(html);
        } else if ($el.html() != html) {
            const $areas = $el.find('[data-snippet-area]');

            if ($areas.length) {
                const snippetAreas = $areas
                    .toArray()
                    .reduce((acc, item) => {
                        acc[item.dataset.snippetArea] = $(item);
                        return acc;
                    }, {});

                let $buffer = $('<div />').html(html);
                const nextSnippetAreas = $buffer
                    .find('[data-snippet-area]')
                    .toArray()
                    .reduce((acc, item) => {
                        acc[item.dataset.snippetArea] = $(item);
                        return acc;
                    }, {});

                if (Object.keys(snippetAreas).join('') === Object.keys(nextSnippetAreas).join('')) {
                    Object.keys(nextSnippetAreas).forEach(areaId => {
                        if (areaId in snippetAreas) {
                            removeComponents(snippetAreas[areaId][0]);
                            snippetAreas[areaId].html(nextSnippetAreas[areaId].html());
                        }
                    });
                } else {
                    removeComponents($el[0]);
                    $el.html(html);
                }

                $buffer = null;
            } else {
                removeComponents($el[0]);
                $el.html(html);
            }
        }
        if ($el[0]) {
            loadComponents($el[0]);
        }
        EventBus.emit('nette:snippet:afterchange', {
            id: $el.attr('id')
        })
    },
    escapeSelector: function (selector) {
        // thx to @uestla (https://github.com/uestla)
        return selector.replace(/[\!"#\$%&'\(\)\*\+,\.\/:;<=>\?@\[\\\]\^`\{\|\}~]/g, '\\$&');
    }
});

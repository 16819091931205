import { triggerEvent } from '../../utils/dom'
import EventBus from '../../core/EventBus'

var timer = null

const defaults = {
    ajax: false,
    data: null,
    onStart: () => {},
    onComplete: () => {}
}

export function submitForm(node, options = {}) {
    if (!node) {
        return
    }

    options = {
        ...defaults,
        ...options
    }

    const form = node.closest('form')

    if (options.ajax || form.classList.contains('ajax')) {

        return $.nette.ajax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: options.data ? options.data : $(form).serialize(),
            beforeSend: () => {
                const id = form.getAttribute('id')
                if (options.onStart) {
                    options.onStart()
                }
                form.classList.add('is-loading')
                document.documentElement.classList.add(`is-${id}-loading`)
                EventBus.emit('nette:ajax:submit', {
                    id
                })
            },
            complete: () => {
                if (form) {
                    const id = form.getAttribute('id')
                    document.documentElement.classList.remove(`is-${id}-loading`)
                    form.classList.remove('is-loading')
                }
                if (options.onComplete) {
                    options.onComplete()
                }
            }
        });
    } else {
        form.submit()
    }
}

document.addEventListener('nette:autosubmit', event => {
    if (event.defaultPrevented) {
        return
    }

    submitForm(event.target)
})

document.addEventListener('change', event => {
    const form = event.target.closest('form')
    const field = event.target.closest('select[data-autosubmit], input[data-autosubmit]')

    if (field || form?.dataset?.autosubmit != null) {
        triggerEvent(form, 'nette:autosubmit')
    }
})

document.addEventListener('keyup', event => {
    const form = event.target.closest('form')
    const field = event.target.closest('input[data-autosubmit], textarea[data-autosubmit]')

    if (field || form?.dataset?.autosubmit != null) {
        let delay = parseInt(form.dataset.autosubmitDelay)
        if (isNaN(delay)) {
            delay = 200
        }
        clearTimeout(timer)
        timer = setTimeout(() => triggerEvent(form, 'nette:autosubmit'), delay)
    }
})
(function() {

    $('form').each(function () {

        var $buffer = $('<div />');

        var $noscript = $(this).find('noscript');

        if ($noscript.data('component') === 'Antispam') {
            return
        }

        $buffer.html($(this).find('noscript').text());

        var key = $buffer.find('label[for="agreeemail"]').find('strong').text();
        var name = $buffer.find('.anti-fill').attr('name');

        if (key &&  name) {
            $(this).append($('<input type="hidden" name="' + name + '" value="' + key + '">'));
        }

    });

})();

import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

const options = {
    dsn: document.documentElement.dataset.sentryDsn || '',
    allowUrls: window.location.hostname,
    beforeSend(event, hint) {
        const errorMessage = hint?.originalException?.message
        const facebookBrowser = /FBAN/.test(navigator.userAgent)

        if (errorMessage) {
            if (errorMessage.match(/formAvailible/)) {
                return null
            }

            if (errorMessage.match(/Can\'t find variable\: DOT/)) {
                return null
            }

            if (errorMessage.match(/[a-z1-9_]+ is not defined/)) {
                return null
            }

            if (facebookBrowser && errorMessage.match(/Blocked a frame with origin/)) {
                return null
            }

            if (errorMessage.match(/evaluating \'window\.webkit\.messageHandlers/)) {
                return null
            }

            if (errorMessage.match(/vc_request_action is not defined/)) {
                return null
            }
        }

        return event
    },
    integrations: [
        new TracingIntegrations.BrowserTracing({
            tracingOrigins: ['https://www.kama.cz'],
        }),
    ],
    tracesSampleRate: 0.2,
}

if (document.documentElement.dataset.sentryEnvironment) {
    options.environment = document.documentElement.dataset.sentryEnvironment
}

if (options.dsn) {
    Sentry.init(options)
    Sentry.configureScope((scope) => {
        scope.setTag('logger', 'js')
        scope.setTag('module', 'frontend')
    })
}

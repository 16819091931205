import ScrollService from './NativeScroll'
// import ScrollService from './VirtualScroll'

ScrollService.getScrollOffset = () => {
    const headerEl = document.querySelector('.Header')
    if (!headerEl) {
        return 0
    }
    return headerEl.offsetHeight + headerEl.offsetTop + 10
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService
// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill'
import 'classlist-polyfill'
import 'custom-event-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'
import 'corejs-typeahead'

import './plugins/nette.ajax'
import './plugins/nette.ajax.history'
// import './plugins/giant.autosubmit'
// import './plugins/giant.show-if'
import './modules/antispam'

// nette
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'
import './nette/NetteAutosubmit'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.ext('history').cache = false // prekracovany limit pro velikost state objektu
    $.nette.init()
}

import TouchMouseInputResolver from './components/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { loadComponents } from './core/componentUtils'
loadComponents()
/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'


import ScrollService from "./services/Scroll"
ScrollService.useNative()
setScrollPosition()

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}